import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/investments"

import offices from "data/offices"

const Apartments = ({ location }) => {
  const title = "Kielce"

  return (
    <Layout
      location={location}
      seo={{
        title: "Kielce - Nowe biura na sprzedaż",
        description:
          "Biura na sprzedaż bezpośrednio od sprawdzonego dewelopera. Najlepsze lokalizacje, przemyślane projekty i zawsze dużo zieleni.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{
          label: "Offices and Premises",
          url: "/en/offices-and-premises",
        }}
      />
      <PageHeader title={title} />
      {offices
        ?.filter(item => item.city === "Kielce")
        .map((item, index) => (
          <Content
            key={index}
            city={item?.city}
            sub_title="Projects on sale"
            investments={item?.investments}
            office={item?.office}
            rmSubHeader
          />
        ))}
    </Layout>
  )
}

export default Apartments
